import React, { createContext, useEffect, useState } from "react";
import  useAuth from "../providers/AuthProvider";
import  useData from "../providers/DataProvider";
export const GeneralBrainContext = createContext();

export default function BrainContextProvider ({ children }) {
  //contexts
  const { userNickname, userName, userEmail, ipAddress } = useData();
  const { currentUser } = useAuth(); 

  //states
  const [isMobile, setIsMobile] = useState(false);
  const [dropdowns, setDropdowns] = useState(Array(18).fill(false));

  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = !updatedDropdowns[index];

    for (let i = 0; i < updatedDropdowns.length; i++) {
      if (i !== index) {
        updatedDropdowns[i] = false;
      }
    }

    setDropdowns(updatedDropdowns);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const contextValue = {
    isMobile,
    toggleDropdown,
    dropdowns,
    setDropdowns,


    // ---------- Auth
    currentUser,

    // ---------- Data
    userNickname,
    userName,
    userEmail,
    ipAddress,
  };

  return (
    <GeneralBrainContext.Provider value={contextValue}>
      {children}
    </GeneralBrainContext.Provider>
  );
};

