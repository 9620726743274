import React, { createContext, useState } from "react";

export const GeneralAuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [showOverBoard, setShowOverBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const value = {
    currentUser,
    setCurrentUser,
    loading,
    setLoading,
    userData,
    setUserData,
    showOverBoard,
    setShowOverBoard,
  };

  return (
    <GeneralAuthContext.Provider value={value}>
      {children}
    </GeneralAuthContext.Provider>
  );
}
