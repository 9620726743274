import React from "react";

const PageIsLoading = () => {
  return (
    <div className="h-dvh flex justify-center items-center bg"> 
      <div className="wrapperleg">
        <div className="circleleg"></div>
        <div className="circleleg"></div>
        <div className="circleleg"></div>
        <div className="shadowleg"></div>
        <div className="shadowleg"></div>
        <div className="shadowleg"></div>
      </div>
    </div>
  );
};

export default PageIsLoading;
