import "./App.css";
import "./styles/General.css";
import "./styles/Med1.css";
import "./styles/Med2.css";
import "./styles/Med3.css";
import "./styles/Med4.css";
import "./styles/Reuse.css";
import "./styles/Blog.css";
import AuthContextProvider from "./contexts/AuthContext";
import DataContextProvider from "./contexts/DataContext";
import BrainContextProvider from "./contexts/BrainContext";
import ThemeContextProvider from "./contexts/ThemeContext";
import TranslateContextProvider from "./contexts/TranslateContext";
import { lazy, Suspense } from "react";
import PageIsLoading from "./components/loaders/PageIsLoading";

const MainRouter = lazy(() => import("./routes/Main"));
const AuthRouter = lazy(() => import("./routes/Auth"));
const ChatRouter = lazy(() => import("./routes/Chat"));
const PayRouter = lazy(() => import("./routes/Pay"));
const CrmRouter = lazy(() => import("./routes/Crm"));
const VoiceRouter = lazy(() => import("./routes/Voice"));
const BlogRouter = lazy(() => import("./routes/Blog"));

const hostname = window.location.hostname;

const subdomain =
  hostname.split(".").length > 0 ? hostname.split(".")[0] : null;

const routerMap = {
  main: MainRouter,
  auth: AuthRouter,
  chat: ChatRouter,
  pay: PayRouter,
  crm: CrmRouter,
  voice: VoiceRouter,
  blog: BlogRouter,
};

const AppRouter = routerMap[subdomain] || MainRouter;

function App() {
  return (
    <>
      <ThemeContextProvider>
        <TranslateContextProvider>
          <AuthContextProvider>
            <DataContextProvider>
              <BrainContextProvider>
                <Suspense fallback={<PageIsLoading />}>
                  <AppRouter />
                </Suspense>
              </BrainContextProvider>
            </DataContextProvider>
          </AuthContextProvider>
        </TranslateContextProvider>
      </ThemeContextProvider>
    </>
  );
}

export default App;
